<template>
  <div>
    <div v-if="items === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div v-if="items !== null">
      <v-list>
        <template v-for="(item, index) in slicedItems">
          <BulkEditItemListItem
            :key="item.uuid"
            :item="slicedItems[index]"
            :selected-fields="selectedFields"
            @changed="onItemChange"
          />
          <v-divider :key="index" />
        </template>
      </v-list>
      <div v-intersect.quiet="loadMore" />
      <v-progress-circular
        v-if="isLoadingMore"
        indeterminate
        color="primary"
      />
    </div>
  </div>
</template>

<script>
	import BulkEditItemListItem from "./BulkEditItemListItem"

	export default {
		name: "BulkEditItemList",
		props: {
			items: {
				type: Array,
				default: () => []
			},
			selectedFields:	{
				type: Array,
				default: () => []
			},
			originalItems: {
				type: Array,
				default: () => []
			},
			modifiedItems: {
				type: Array,
				default: () => []
			}
		},
		components: {
			BulkEditItemListItem,
		},
		data() {
			return {
				startIndex: 0,
				length: 50,
				step: 50,
				isLoadingMore: false,
				itemsToUpdate: []
			}
		},
		computed: {
			slicedItems() {
				return this.items.slice(0, this.length)
			},
		},
		methods: {
			loadMore() {
				this.length = this.length + this.step
			},
			onItemChange(value) {
				const itemIndex = this.originalItems.findIndex(item => item.uuid === value.uuid)

				let deepEqual = function(x, y) {
				return (x && y && typeof x === 'object' && typeof y === 'object') ?
					(Object.keys(x).length === Object.keys(y).length) &&
					Object.keys(x).reduce(function(isEqual, key) {
					return isEqual && deepEqual(x[key], y[key])
					}, true) : (x === y)
				}

				let diff = function (obj1, obj2) {

					if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
						return obj1
					}

					let diffs = {}
					let key

					let compare = function (item1, item2, key) {
						let type1 = Object.prototype.toString.call(item1)
						let type2 = Object.prototype.toString.call(item2)

						if (type2 === '[object Undefined]') {
							diffs[key] = null
							return
						}

						if (type1 !== type2) {
							diffs[key] = item2
							return
						}

						if (type1 === '[object Object]') {

							var objDiff = diff(item1, item2)

							if (Object.keys(objDiff).length > 0) {
								diffs[key] = objDiff
							}
							return
						}

						if (type1 === '[object Function]') {
							if (item1.toString() !== item2.toString()) {
								diffs[key] = item2
							}
						} else {
							if (item1 !== item2 ) {
								diffs[key] = item2
							}
						}

					}

					for (key in obj1) {
						if (Object.prototype.hasOwnProperty.call(obj1, key)) {
							compare(obj1[key], obj2[key], key)
						}
					}

					for (key in obj2) {
						if (Object.prototype.hasOwnProperty.call(obj2, key)) {
							if (!obj1[key] && obj1[key] !== obj2[key] ) {
								diffs[key] = obj2[key]
							}
						}
					}

					return diffs
				}

				if(!deepEqual(this.originalItems[itemIndex], value)) {
					let objIndex = this.itemsToUpdate.findIndex((item => item.uuid === value.uuid))
					let objDifference = diff(this.originalItems[itemIndex], value )

					if (objDifference.price) {
						objDifference.price.currency_code = this.originalItems[itemIndex].price.currency_code
						objDifference.price.taxRate = this.originalItems[itemIndex].price?.data?.include_tax
					}

					if (objDifference.sale_price) {
						objDifference.sale_price.currency_code = this.originalItems[itemIndex].sale_price.currency_code
						objDifference.sale_price.taxRate = this.originalItems[itemIndex].price?.data?.include_tax
					}

					if (objDifference.cost_price) {
						objDifference.cost_price.currency_code = this.originalItems[itemIndex].cost_price.currency_code
						objDifference.cost_price.taxRate = this.originalItems[itemIndex].price?.data?.include_tax
					}

					let objToPush = {
						uuid: value.uuid,
						title: value.title,
						description: this.originalItems[itemIndex].description,
						...objDifference
				}

				if(objIndex >= 0){
					this.itemsToUpdate[objIndex] = objToPush
				} else {
					this.itemsToUpdate.push(objToPush)
				}
				} else {
				let objIndex = this.itemsToUpdate.findIndex((item => item.uuid === value.uuid))

				if(objIndex >= 0){
					this.itemsToUpdate.splice(objIndex,1)
				}
				}

				this.$emit('itemsChanged', this.itemsToUpdate)
			}
		},
		watch: {
			modifiedItems(newValue) {
				if(Array.isArray(newValue) && newValue.length <= 0) {
					this.itemsToUpdate = []
				}
			}
		}
	}
</script>

<style scoped>

</style>
